import { ButtonType } from 'holded/modules/cms/domain/components/link';
import { ImageRes } from 'holded/modules/cms/domain/imageMedia';

import { CustomTag } from './styles';

type Background = 'light' | 'soft' | 'dark';

type Header1Section = {
  id?: number;
  __component?: string;
  title: string;
  titleSecondary: string;
  description: string;
  background: Background;
  customTag: CustomTag;
};

type Header2Section = {
  id?: number;
  __component: string;
  title: string;
  description: string;
  image?: ImageRes;
  imageMobile?: ImageRes;
  cards: Card[];
  background: Background;
  customTag: CustomTag;
};

type Card = {
  id?: number;
  title: string;
  description: string;
  icon: string;
  image?: ImageRes;
  button: ButtonType;
};

const isButtonsVisible = (): boolean => {
  const showErrorWarning = process.env.NEXT_PUBLIC_SHOW_ERROR_WARNING === 'true';

  return !showErrorWarning;
};

export { isButtonsVisible };

export type { Card, Header1Section, Header2Section };
